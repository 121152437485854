// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-note-jsx": () => import("./../../../src/templates/note.jsx" /* webpackChunkName: "component---src-templates-note-jsx" */),
  "component---src-templates-note-map-jsx": () => import("./../../../src/templates/note-map.jsx" /* webpackChunkName: "component---src-templates-note-map-jsx" */),
  "component---src-templates-sitemap-jsx": () => import("./../../../src/templates/sitemap.jsx" /* webpackChunkName: "component---src-templates-sitemap-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */),
  "component---src-templates-tag-list-jsx": () => import("./../../../src/templates/tag-list.jsx" /* webpackChunkName: "component---src-templates-tag-list-jsx" */)
}

